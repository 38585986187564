@use "../config" as *;
.sticky {
    &.stats-page {
        --header-height: var(--secondary-header-height);
        &.webview {
            --header-height: 0rem;
        }
    }
    .stats-header {
        box-shadow: 0 .2rem .5rem hsl(var(--hsl-black)/0.4);
        @include position(0, null, null, 0, fixed);
        @extend %w-100;
        @extend %px-0;
        .sub-ul {
            width: var(--content-width);
        }
        .site-nav {
            @extend %w-100;
        }
    }
    .header-wrap {
        display: none;
    }
}
.stats-page {
    .stats-header {
        display: block;
    }
}
.stats-header {
    z-index: var(--z-secondary-header);
    width: var(--container-max-width);
    // overflow: hidden;
    // top: calc((var(--header-height) + ((9/16)*var(--window-inner-width) + 40.8rem)) - var(--secondary-header-height));
    top: 25.7rem;
    height: var(--secondary-header-height);
    transition: all .4s ease;
    @extend %mx-auto;
    @extend %px-0;
    @include position(null, 0, null, 0);
    @extend %white-bg;
    .site-nav {
        @extend %relative;
    }
    .nav {
        &-link-list {
            list-style: none;
            height: var(--secondary-header-height);
            @extend %flex;
            @extend %px-0;
        }
        &-link {
            min-width: max-content;
            flex: 1;
            @extend %px-5;
            @extend %relative;
            @extend %flex-c-c;
            &:not(:last-child):after {
                content: '';
                right: 0;
                width: .1rem;
                @extend %h-60;
                @extend %black-bg-5;
                @extend %position-y-center;
            }
            &.active {
                padding-inline: var(--space-4) var(--space-2);
                @extend %primary-bg;
                > .nav-anchor {
                    > .nav-text,
                    > .btn-text {
                        font-weight: bold;
                        @extend %white;
                    }
                    &::after {
                        @extend %white;
                    }
                }
            }
        }
        &-anchor {
            @extend %h-100;
            @extend %w-100;
            @extend %px-0;
            @extend %flex-c-c;
            &::before {
                content: unset;
            }
            .btn-text {
                @extend %capitalize;
                @extend %font-14-pr;
                @extend %secondary-light;
            }
        }
        &-text {
            line-height: 1.2;
            @extend %text-center;
            @extend %capitalize;
            @extend %font-14-pr;
            @extend %secondary-light;
        }
    }
    button.nav-anchor {
        position: relative;
        &::after {
            line-height: 1;
            @extend %transition;
            @extend %px-2;
            @include icon(chevron-down, 14);
        }
    }
    .sub {
        &-nav-link {
            height: var(--secondary-header-height);
            @extend %px-4;
            @extend %relative;
            @extend %flex-c-c;
            &:not(:last-child):after {
                content: '';
                height: .1rem;
                left: auto;
                @extend %w-90;
                @extend %secondary-bg-3;
                @extend %position-b-l;
            }
            &.active {
                background: transparent;
                .nav-anchor,
                .anchor {
                    .nav-text {
                        @extend %club-secondary;
                    }
                }
            }
        }
        &-ul {
            list-style: none;
            width: calc(var(--content-width) - var(--space-4));
            -webkit-backdrop-filter: blur(2rem);
            backdrop-filter: blur(2rem);
            @extend %white-bg-5;
            @extend %pl-0;
            @include dropdown(close);
            @include position(var(--secondary-header-height), 0, null, auto);
            .nav-anchor {
                justify-content: flex-start;
            }
        }
    }
    .sub-ul {
        .nav-text {
            @extend %capitalize;
            @extend %font-12-pm;
            @extend %secondary-light;
        }
    }
    [aria-expanded="true"] {
        &::after {
            transform: rotate(180deg);
        }
        ~ .sub-ul {
            @include dropdown(open);
        }
    }
}
@media (min-width:$tablet-min-width) {
    .sticky {
        .header-wrap {
            display: none;
        }
        .stats-header {
            height: var(--secondary-header-height);
            width: var(--content-width);
            display: flex;
            padding-inline: var(--container-white-space);
            top: 0;
            .sub-ul {
                width: 100%;
            }
        }
    }
    .search-page {
        &.sticky {
            .stats-header {
                display: flex;
                top: 0;
            }
        }
    }
    .stats-header {
        padding-inline: 0;
        width: var(--container-max-width);
        top: 25.3rem;
        .nav-link-list {
            height: var(--secondary-header-height);
        }
        .sub-ul {
            top: var(--secondary-header-height);
            width: 100%;
        }
    }
}