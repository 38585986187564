@use "../config" as *;

.waf-row-home-news,
.waf-row-club-news,
.waf-row-club-video,
.waf-row-home-video,
.waf-row-club-photo,
.waf-row-home-photo{
    @extend %common-card-with-more-btn;
    .waf-listing{
        @extend %py-6;
    }
}
.waf-row-home-news,
.waf-row-club-news {
    .item-type-article .article-content::before {
        display: none;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-component {
        &.waf-listing {
            padding-inline: 0;
            padding-block: var(--space-12);
        }
    }
    .waf-row-home-news,
    .waf-row-club-news,
    .waf-row-club-video,
    .waf-row-home-video,
    .waf-row-club-photo,
    .waf-row-home-photo{
        @include commonCardWithMoreButton(overlay, 4); 
    }
    .waf-row-home-news,
    .waf-row-club-news {
        .waf-listing {
            .article {
                &-title {
                    -webkit-line-clamp: 2;
                    height: 4.6rem;
                }
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .waf-row-home-news,
    .waf-row-club-news {
        .waf-listing {
            .article {
                &-list {
                    @include card-count(3, var(--space-3));
                }
            }
        }
    }
}