@use './config/' as *;
@forward './main-scss/';
@forward "./listing/listing";
@forward './listing/common-listing';
@forward './stats/stats-nav';
.waf-search {
    $input-height: 5rem;
    padding-inline: var(--space-1);
    .squad-item,
    .club-item {
        @extend %mb-4
    }
    .waf-head {
        isolation: unset;
        .head-wrap {
            padding-bottom: 4.5rem;
            @extend %flex-wrap;
            .title {
                @extend %w-100;
            }
        }
    }
    .waf-body {
        @extend %p-2;
    }
    .search {
        &-section-listing {
            container-type: inline-size;
            display: flex;
            flex-direction: column;
            @extend %relative;
            > .videos {
                order: 1;
            }
            > .photos {
                order: 2;
            }
            > .news {
                order: 3;
            }
            > .features {
                order: 4;
            }
            > .interviews {
                order: 5;
            }
            > .pressreleases {
                order: 6;
            }
        }
        &-input {
            max-width: 65rem;
            z-index: var(--z-search-head);
            @extend %relative;
            @extend %white-bg;
            @extend %p-0;
            @extend %w-100;
            @extend %flex;
            @extend %quarter-radius;
            .form-control {
                border: 0;
                height: $input-height;
                @extend %w-100;
                @extend %p-2;
                @extend %h-100;
            }
            .btn {
                width: $input-height;
                height: $input-height;
                flex-shrink: 0;
                &::before {
                    @extend %secondary-dark;
                }
                &-text {
                    @extend %font-0;
                    &::before {
                        @extend %d-none;
                    }
                }
                &-close {
                    @include separator(50%, y, 0.1rem, secondary);
                    &::before {
                        @include icon(close, 14);
                    }
                }
                &-search {
                    &::before {
                        @include icon(search, 14);
                    }
                }
            }
        }
        &-active {
            .search-list {
                @include dropdown(open);
            }
        }
        &-list {
            position: absolute;
            left: 0;
            top: $input-height;
            list-style: none;
            max-height: calc(var(--window-height) - 35rem);
            overflow: auto;
            @include dropdown(close);
            @include border(1, secondary-dark, 2, top);
            @extend %px-1;
            @extend %white-bg;
            @extend %w-100;
        }
        &-item {
            cursor: pointer;
            @extend %p-1-2;
            @extend %secondary;
            @extend %text-left;
            @extend %font-12-pr;
            @extend %quarter-radius;
            &:hover {
                @extend %font-12-pb;
                @extend %secondary-bg-1;
            }
        }
        &-result-squad {
            @extend %common-card-with-more-btn;
        }
        // waf body starts
        &-highlight {
            @extend %primary;
        }
        &-count {
            font-weight: 700;
        }
        &-text {
            @extend %font-14;
        }
        &-head {
            @include border(1, secondary-dark, 5, bottom);
            @extend %pb-4;
            .no-data {
                @extend %primary;
                @extend %font-14-pb;
            }
        }
        &-result-item {
            @extend %relative;
            @extend %my-4;
            .head-wrap {
                @extend %d-none;
                padding-bottom: 0;
            }
        }
        &-players {
            .search-result-squad .head-wrap {
                display: block;
            }
        }
    }
    .no-data-wrap {
        width: 20rem;
        height: 20rem;
        @include bgImg('svg/search.svg');
        @extend %my-6;
        @extend %mx-auto;
        .no-data {
            @extend %d-none;
        }
    }
    .club {
        &-item {
            @extend %half-radius;
            @extend %relative;
            @extend %hidden;
            @extend %transition;
            &::after {
                content: '';
                width: 8rem;
                height: 8rem;
                filter: invert(1) brightness(0.5);
                opacity: 0.2;
                top: -0.6rem;
                pointer-events: none;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: -3rem -3rem;
                transition: 300ms ease-in-out;
                @include bgImg("svg/hexagon.svg");
                @extend %position-t-l;
            }
            .btn-outline {
                border: 0;
                @extend %font-0;
                @extend %position-center;
                &:hover {
                    @extend %transparent-bg;
                }
            }
        }
        &-head {
            aspect-ratio: 16/9;
            img {
                aspect-ratio: 16/9;
            }
        }
        &-body {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            background: linear-gradient(125deg, var(--club-primary) 0%, var(--club-secondary) 100%);
            @extend %relative;
            @extend %pt-8;
            @extend %pb-4;
            @extend %mt-1-neg;
            @extend %text-center;
        }
        &-logo {
            width: 5.3rem;
            height: 5.3rem;
            margin-top: -6.5rem;
            outline: 0.3rem solid hsl(var(--hsl-white)/0.2);
            @include bg(white);
            @extend %p-2;
            @extend %mx-auto;
            @extend %mb-2;
            @extend %circle-radius;
        }
        &-img {
            object-fit: contain;
            object-position: center;
        }
        &-text {
            @extend %mb-3;
            .name {
                @extend %white;
                @extend %font-18-pb;
            }
            .short-name {
                @extend %d-none;
            }
        }
        &-venue {
            max-width: 90%;
            @extend %w-100;
            @extend %relative;
            @extend %capitalize;
            @extend %white;
            @extend %mx-auto;
            @extend %text-center;
            &:before {
                @include icon(stadium);
                @extend %mr-1;
            }
        }
    }
    .club-item:after {
        content: unset;
    }
    .squad {
        &-item {
            @extend %pb-3;
            &:not(:last-child) {
                border-bottom: .1rem solid hsl(var(--hsl-black)/0.7);
            }
            &:hover {
                .card-action {
                    @extend %position-t-r;
                }
            }
        }
    }
    .player {
        &-bio {
            @include truncate(5, 14);
            @extend %mt-3;
        }
        &-wrap {
            cursor: pointer;
            @extend %relative;
        }
        &-thumbnail {
            isolation: isolate;
            flex-direction: row-reverse;
            background: linear-gradient(0.57deg, var(--club-primary) -6.68%, var(--club-varient) 99.86%);
            aspect-ratio: 11/9;
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            background-repeat: no-repeat;
            background-size: cover;
            @extend %flex-sb-n;
            @extend %relative;
            &::after {
                content: "";
                background-size: cover;
                background-repeat: no-repeat;
                opacity: 0.1;
                z-index: var(--z-bg-pattern-neg);
                width: 112%;
                height: 130%;
                filter: invert(1);
                @include bgImg("svg/hexagon.svg");
                @include position(-26%, 2%);
            }
            .icon-time-wrapper {
                @extend %d-none;
            }
        }
        &-image {
            z-index: var(--z-negative);
            @extend %w-80;
            @extend %h-100;
            @include position(null, 0.8rem, 0);
        }
        &-country {
            @extend %d-none;
        }
        &-number {
            height: max-content;
            opacity: 0.15;
            -webkit-text-stroke: 0.1rem hsl(var(--hsl-white));
            @extend %font-60-pb;
            @extend %mr-3;
            @extend %mt-2;
        }
        &-content {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            z-index: var(--z-bg-pattern);
            @include border(1, secondary-dark, 3);
            @extend %relative;
            @extend %white-bg;
            @extend %p-3;
            &::after {
                content: "";
                background-size: cover;
                background-repeat: no-repeat;
                z-index: var(--z-bg-pattern-neg);
                mix-blend-mode: overlay;
                background-size: 100% 100%;
                @include bgImg("cssimages/card-dotted-lines.png");
                @include position(0, null, null, 0);
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-stats {
            &-item {
                @include border(1, secondary-medium, 1, bottom);
                @extend %text-center;
                @extend %pb-1;
                @extend %mb-1;
                &:last-of-type {
                    border-bottom: 0;
                    @extend %pb-0;
                    @extend %mb-0;
                }
            }
            &-list {
                width: 27%;
                padding-inline: 3%;
                @extend %hidden;
                @extend %half-radius;
                @extend %white-bg;
                @extend %my-2;
                @extend %ml-2;
                @extend %flex-column-c-n;
                @extend %relative;
                &::after {
                    content: "";
                    width: 9.1rem;
                    height: 10.5rem;
                    background-size: 9.1rem;
                    background-repeat: no-repeat;
                    opacity: 0.1;
                    @include bgImg("svg/hexagon.svg");
                    @include position(null, null, -1rem, -6rem);
                }
            }
            &-title {
                @extend %font-10-pm;
                @extend %uppercase;
                @extend %text-center;
            }
            &-count {
                @extend %font-20-pb;
            }
        }
        &-name {
            height: 8.2rem;
            @extend %hidden;
            @extend %flex-column-c-n;
            .name {
                @extend %uppercase;
                @extend %mb-1;
                @extend %font-16-pr;
                &.last-name {
                    @extend %font-16-pb;
                }
            }
            .icon {
                justify-content: center;
                align-items: center;
                width: 2rem;
                height: 2rem;
                aspect-ratio: 1/1;
                transform: translateY(-50%);
                @include position(50%, 1.5rem);
                @extend %circle-radius;
                @extend %white-bg;
                @extend %d-none;
                &::after {
                    content: "";
                    @extend %font-14-pb;
                }
                &::before {
                    content: "";
                    width: calc(100% + 0.6rem);
                    height: calc(100% + 0.6rem);
                    aspect-ratio: 1/1;
                    @include position(-0.3rem, null, null, -0.3rem);
                    @extend %white-bg-2;
                    @extend %circle-radius;
                }
            }
        }
        &-role {
            .role {
                display: inline-block;
                @extend %club-primary-bg;
                @extend %uppercase;
                @extend %white;
                @extend %half-radius;
                @extend %px-2;
                @extend %py-1;
            }
        }
        &-wrap {
            @extend %y-hidden;
            @extend %relative;
            .card-action {
                aspect-ratio: 11/9;
                @include backdropBlur(2.5rem);
                @include position(calc(100% - 8.8rem), null, null, 0);
                @extend %club-primary-bg-2;
                @extend %half-radius;
                @extend %w-100;
                @extend %flex-c-c;
                @extend %transition;
            }
            .btn-site {
                display: inline-block;
                height: max-content;
                @include border(1, white, 10, all);
                @extend %px-3;
                @extend %uppercase;
                @extend %transparent-bg;
                &:hover {
                    @extend %white;
                }
            }
        }
    }
    .captain {
        .player-content {
            background: linear-gradient(84.82deg, var(--club-secondary) -38.35%, var(--club-primary) 64.69%);
        }
        .player-name {
            .name {
                @extend %white;
            }
            .icon {
                display: inline-flex;
                &::after {
                    content: "C";
                }
            }
        }
    }
    .search-wrap:not(.search-result-all) {
        .article {
            &-list {
                overflow: hidden;
                display: grid;
            }
            &-item {
                width: 100%;
            }
        }
        .head-tab {
            display: none;
        }
    }
    .listing {
        &-element {
            @include border(1, secondary, 2, bottom);
            @include listing-card(card);
            @extend %white-bg;
            @extend %relative;
            @extend %common-gradient;
            @extend %common-card-with-more-btn;
            @extend %pt-3;
            @extend %pb-5;
            @extend %my-3;
            @extend %mx-3-neg;
            @extend %px-3;
            .title {
                @extend %uppercase;
            }
            .head-tab {
                bottom: 2.5rem;
                list-style: none;
                right: 1.5rem;
            }
            .article {
                &-title {
                    @extend %font-18;
                }
                &-list {
                    @extend %mx-3-neg;
                    @extend %px-3;
                    @extend %mb-1;
                }
                &-item:hover {
                    .img-box {
                        &::before {
                            display: none;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
            .img-box::before {
                background: linear-gradient(180deg, hsl(var(--hsl-secondary-dark)/0.7) 14.52%, hsl(var(--hsl-primary)/0.9) 104.88%);
            }
        }
        &-body {
            @include separator(100%, x, 0.1rem, secondary)
        }
    }
}
.search-page {
    .stats-header {
        display: block;
        top: 30.7rem;
        overflow: unset;
    }
    &.sticky {
        .search-input {
            z-index: var(--z-sticky-head);
        }
        .stats-header {
            top: 0;
        }
    }
}
@include mq(col-md) {
    .waf-search {
        .waf-head {
            .head-wrap {
                padding-bottom: 5rem;
            }
        }
        .search {
            &-body {
                display: flex;
                justify-content: space-between;
                gap: var(--space-6);
                > :last-child {
                    flex: 1;
                }
            }
            &-section {
                &-data {
                    order: 2;
                    flex: 0 0 30rem;
                    container-type: inline-size;
                }
                &-listing {
                    flex: 1;
                }
            }
        }
        .listing {
            &-element {
                margin-top: 0;
                .head-wrap {
                    display: flex;
                    align-items: center;
                    gap: var(--space-3);
                }
                .title {
                    width: auto;
                    margin-top: var(--space-1);
                }
                .head-tab {
                    position: static;
                    a {
                        padding: var(--space-1) var(--space-2);
                        border-radius: var(--half-radius);
                        font-size: 0;
                        gap: var(--space-0);
                        transition: 300ms ease-in-out;
                        @include bg(primary);
                        &:hover {
                            padding: var(--space-1) var(--space-3);
                            gap: var(--space-1);
                            font-size: 1.1rem;
                            color: var(--white);
                        }
                        &::after {
                            width: unset;
                            height: unset;
                            border-radius: 0;
                        }
                    }
                }
                background-color: transparent;
                .article {
                    &-list {
                        margin-bottom: var(--space-4);
                        @include grid(3, var(--space-3))
                    }
                    &-item {
                        width: 100%;
                    }
                }
            }
            &-body::after {
                display: none;
            }
        }
        .club-item,
        .squad-item {
            margin-bottom: var(--space-4);
        }
        @container (width > 70vw) {
            .listing-element {
                .article-list {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            .squad-list,
            .club-list {
                @include grid(4, var(--space-4));
            }
        }
    }
}